import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import GrupoCategoriaModel from 'src/model/veiculo/GrupoCategoriaModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'

export default {
  name: 'ConteudoForm',
  components: { DpForm },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      modelPadrao: new GrupoCategoriaModel()
    }
  }
}
